import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowLeft } from 'react-icons/fi';


const ButtonBack = () => {
  function goBack() {
    window.history.back();
  }

  return (
    <div className="back-pages">
      <div className="back-pages-inner">
        <button onClick={goBack} className='icons'>
          <FiArrowLeft />
          <span>Back</span>
        </button>
      </div>
    </div>
  );
};

ButtonBack.propTypes = {
  section: PropTypes.string,
};

export default ButtonBack;
