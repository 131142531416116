import React from 'react';
import { shape } from 'prop-types';

import MediaImage from '../../../Elements/MediaImage';
import { MediaImageType } from '../../../../types';

const FullWidthImage = ({ data }) => (
  <section className="section-projects section-projects__img-bar">
    {data.relationships?.image ? (
      <MediaImage media={data.relationships.image.relationships?.image} alt={data.relationships.image.media?.alt} title={data.relationships.image.media?.title} />
    ) : null}
  </section>
);

FullWidthImage.propTypes = {
  data: shape({
    relationships: shape({ image: MediaImageType }),
  }),
};

export default FullWidthImage;
