import React from 'react';
import { oneOf, shape, string } from 'prop-types';

import MediaImage from '../../../Elements/MediaImage';
import Markup from '../../../Elements/Markup';
import { MediaImageType } from '../../../../types';

const ImageAndText = ({ data }) => (
  <section className={`section-projects section-projects__text-img ${data.imagePosition === 'left' ? 'invert' : ''}`}>
    <div className="section-projects__inner">
      <div className="text-img-text">
        {data.title ? <h2>{data.title}</h2> : null}
        {data.body?.processed ? <Markup content={data.body.processed} /> : null }
      </div>
      <div className="text-image-img">
        {data.relationships?.image ? (
          <MediaImage media={data.relationships.image.relationships?.image} alt={data.relationships.image.media?.alt} title={data.relationships.image.media?.title} />
        ) : null}
      </div>
    </div>
  </section>
);

ImageAndText.propTypes = {
  data: shape({
    title: string,
    body: shape({ processed: string }),
    imagePosition: oneOf(['right', 'left']),
    relationships: shape({ image: MediaImageType }),
  }),
};

export default ImageAndText;
