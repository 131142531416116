import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import { ProjectType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';
import Markup from '../../Elements/Markup';
import { getSectionComponent } from './helpers/sectionsHelper';
import ButtonBack from '../../Elements/ButtonBack';

const ProjectFull = ({ project }) => (
  <>
    {/* Header */}
    <section className="project-header">
      <ButtonBack />
      <div className="columns project-header__inner">
        <div className="column project-header__image">
          {project.relationships?.heroImage ? (
            <BackgroundImage
              Tag="div"
              className="img-bg"
              fluid={project.relationships.heroImage.relationships?.image?.localFile?.childImageSharp?.fluid}
              backgroundColor={`#040e18`}
              >
            </BackgroundImage>
          ) : null}
        </div>
        <div className="column project-header__content">
          <div className="project-header__content-inner">
            {/* Logo */}
            {project.relationships?.logo ? (
              <div className="project-loco">
                <MediaImage media={project.relationships.logo.relationships?.image} alt={project.relationships.logo.media?.alt} title={project.relationships.logo.media?.title} />
              </div>
            ) : null}

            {/* Title */}
            <h1>{project.title}</h1>

            {/* Description */}
            {project.description?.processed ? <Markup content={project.description.processed} /> : null}
          </div>
        </div>
      </div>
    </section>

    {/* Sections */}
    {project.relationships?.sections?.map((section) => {
      const Section = getSectionComponent(section);
      return Section ? <Section key={section.id} data={section} /> : null;
    })}
  </>
);

ProjectFull.propTypes = { project: ProjectType.isRequired };

export default ProjectFull;
