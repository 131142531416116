import React from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import ProjectFull from '../components/Sections/Projects/ProjectFull';
import ScrollToTop from '../components/Elements/ScrollToTop';
import { ProjectType } from '../types';
import { getOgImageMetatags } from '../helpers/getOgImageMetatags';
import { useSiteMetadata } from '../hooks/use-site-metadata';

import '../fragments/node/projectFull';

const ProjectTemplate = ({ data, location }) => {
  const { frontendURL } = useSiteMetadata();

  const { project } = data;

  const metatags = project?.metatags;

  // Get OG Image metatag
  const ogImageMeta = getOgImageMetatags(project?.relationships?.previewImage?.ogImageMetatag?.image, frontendURL);
  if (ogImageMeta) metatags.push(...ogImageMeta);

  return (
    <Layout className="project" location={location}>
      <SEO metatags={project?.metatags} />
      {project && <ProjectFull project={project} />}
      <ScrollToTop />
    </Layout>
  );
};

ProjectTemplate.propTypes = {
  location: shape({ href: string }),
  data: shape({ project: ProjectType }),
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($drupal_id: String!) {
    project: nodeProject(drupal_id: {eq: $drupal_id}) {
      ...NodeProjectFull
    }
  }
`;
