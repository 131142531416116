const imageMetatags = ['image', 'og:image', 'twitter:image'];

export const getOgImageMetatags = (image, baseUrl) => {
  let metatags = [];

  const ogImageUrl = image?.localFile?.childImageSharp?.ogImage?.src;

  if (ogImageUrl) {
    metatags = imageMetatags.map(metaName => {
      return {
        tag: 'meta',
        attributes: {
          property: metaName,
          content: baseUrl + ogImageUrl
        }
      };
    });
  }

  return metatags;
};
