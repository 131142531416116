import CTA from '../sections/CTA';
import FullWidthImage from '../sections/FullWidthImage';
import Gallery from '../sections/Gallery';
import ImageAndText from '../sections/ImageAndText';
import Quote from '../sections/Quote';
import Text from '../sections/Text';

const sectionTypes = {
  paragraph__cta: CTA,
  paragraph__full_width_image: FullWidthImage,
  paragraph__gallery: Gallery,
  paragraph__image_text: ImageAndText,
  paragraph__quote: Quote,
  paragraph__text: Text,
};

export const getSectionComponent = section => {
  if (section.type && sectionTypes[section.type]) {
    return sectionTypes[section.type];
  } else {
    return null;
  }
};
