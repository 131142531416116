import React from 'react';
import { shape, string } from 'prop-types';

import Markup from '../../../Elements/Markup';
import DrupalLink from '../../../Elements/DrupalLink';

const Quote = ({ data }) => (
  <section className="section-projects section-projects__contact">
    <div className="section-projects__inner">
      {data?.body ? <Markup content={data.body.processed} /> : null}
      {data?.link ? <DrupalLink title={data.link.title} url={data.link.uri} classes="btn btn-big" /> : null}
    </div>
  </section>
);

Quote.propTypes = {
  data: shape({
    body: shape({ processed: string }),
    link: shape({
      title: string,
      uri: string,
    }),
  }).isRequired
};

export default Quote;
