import React from 'react';
import { string } from 'prop-types';

const Markup = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

Markup.defaultProps = { className: '' };

Markup.propTypes = {
  content: string.isRequired,
  className: string,
};

export default Markup;
