import React from 'react';
import { shape, string } from 'prop-types';

import Markup from '../../../Elements/Markup';

const Text = ({ data }) => (
  <section className="section-projects section-projects__summary">
    {data.content?.processed && <Markup className="section-projects__inner" content={data.content.processed} />}
  </section>
);

Text.propTypes = {
  data: shape({
    content: shape({ processed: string }),
  }).isRequired
};

export default Text;
