import React from 'react';
import { shape, string } from 'prop-types';

import Markup from '../../../Elements/Markup';

const Quote = ({ data }) => (
  <section className="section-projects section-projects__quote">
    <div className="section-projects__inner">
      {data.content?.processed && <Markup className="section-projects__inner" content={data.content.processed} />}
    </div>
  </section>
);

Quote.propTypes = {
  data: shape({
    content: shape({ processed: string }),
  }).isRequired
};

export default Quote;
