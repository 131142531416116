import React from 'react';
import { arrayOf, shape } from 'prop-types';

import MediaImage from '../../../Elements/MediaImage';
import { MediaImageType } from '../../../../types';

const Gallery = ({ data }) => (
  <section className="section-projects section-projects__galery">
    <div className="section-projects__galery-inner">
      {data.relationships?.images?.map((image) => (
        <div className="galery-item" key={image.id}>
          <MediaImage media={image.relationships?.image} alt={image.media?.alt} title={image.media?.title} />
        </div>
      ))}
    </div>
  </section>
);

Gallery.propTypes = {
  data: shape({
    relationships: shape({ images: arrayOf(MediaImageType) }),
  }),
};

export default Gallery;
